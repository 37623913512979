<template>
    <div class="c-layout--container c-layout--container-black">
        <Header />
        <slot />
    </div>
</template>

<script setup>
    import navbarController from "assets/js/controller/navbar.ctrl"

    onMounted(() => {
        navbarController.observeHeaderToMutate()
    })
</script>